import React from 'react';
import { PropTypes } from 'prop-types';
import { translateMessage } from './translate-util'

class Translate extends React.Component {
  getMessage() {
    let message = translateMessage(this.props.id, this.context.messages);
    if(this.props.appendedText) {
      message = message + this.props.appendedText;
    }
    if (this.props.replaceText && this.props.replaceVariable) {
      message = message.replace('${' + this.props.replaceVariable + '}', this.props.replaceText);
    }
    return message;
  }

  render() {
    const message = this.getMessage();
    const { className } = this.props;
    return (<span className={className}>{message}</span>);
  }
}

Translate.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  appendedText: PropTypes.string,
  replaceVariable: PropTypes.string,
  replaceText: PropTypes.string
};

Translate.contextTypes = {
  messages: PropTypes.object,
};

export default Translate;
