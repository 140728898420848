import React from 'react';
import { PropTypes as PT } from 'prop-types';


class InternationalizationProvider extends React.Component {
  getChildContext() {
    return {
      messages: this.props.messages,
      countryCode: this.props.countryCode,
      localization: this.props.localization,
    };
  }

  render() {
    return this.props.children;
  }
}

InternationalizationProvider.propTypes = {
  children: PT.object.isRequired,
  messages: PT.object.isRequired,
  countryCode: PT.string.isRequired,
  localization: PT.object
};

// Define the shared value
InternationalizationProvider.childContextTypes = {
  messages: PT.object,
  countryCode: PT.string,
  localization: PT.object
};

export default InternationalizationProvider;
