import React from 'react';
import { PropTypes as PT } from 'prop-types';
import { Redirect } from 'react-router';
import browserInfo from '../../helpers/browser-utils';
import Header from 'components/Common/header';
import Footer from 'components/Common/footer';
import CallingOptions from 'components/CallMe/callingOptions';
import CallingOptionsMobile from 'components/CallMe/callingOptionsMobile';
import DesktopCallout from 'components/CallMe/desktopCallout';
import RTCComp from 'components/CallMe/hasRTC';
import classes from 'styles/CallMe/baseLayout.module.scss';
import queryString from 'query-string';
import ReactSpinner from 'react-spinjs-fix';
import analytics from 'helpers/analytics';
import downloads from 'data/downloads.json';
import extend from 'extend';
import guestFlow from '../../helpers/guestFlow';
import _get from 'lodash/get';
import axios from 'axios';
import _compact from 'lodash/compact';
import InternationalizationProvider from '../../providers/internationalization';

export class BaseLayout extends React.Component {
  static contextType = InternationalizationProvider;
  constructor(props) {
    super(props);
    this.state = {
      hasCheckedLogin: false,
      data: null,
      loading: true,
      show: false,
      notFound: false,
    };
  }

  componentDidMount() {
    this.init();
  }

  getOptionsComponent() {
    if (browserInfo.isMobile || browserInfo.isTablet) {
      return (
        <CallingOptionsMobile
          data={this.state.data}
          extension={this.props.match.params.ext}
          galaxy={this.state.data.extensionMetadata.galaxy}
        />
      );
    }
    return (
      <CallingOptions
        data={this.state.data}
        extension={this.props.match.params.ext}
        galaxy={this.state.data.extensionMetadata.galaxy}
      />
    );
  }

  displayExtensionNotFoundMessage() {
    this.setState({ loading: false, notFound: true });
  }

  async renderApp() {
    const parsed = queryString.parse(this.props.location.search);
    const hash = this.props.location.hash;
    if (hash && hash === '#show') {
      this.setState({
        show: true,
      });
    }

    // Check for logged-in user JWT
    if (parsed.a && parsed.a.length) {
      window.location = `https://${process.env.REACT_APP_ENV_URL}/join/${this.props.match.params.ext}`;
      return;
    }
    // Go thru guest flow
    this.setState({ hasCheckedLogin: true });
    try {
      const extensionData = await guestFlow.getExtensionData(
        this.props.match.params.ext
      );
      if (!_get(extensionData, 'extensionMetadata.groupUUID')) {
        console.warn(`Extension not found: ${this.props.match.params.ext}`);
        this.displayExtensionNotFoundMessage();
        return;
      }
      if (
        await guestFlow.redirectIfSupported(
          _get(extensionData, 'extensionMetadata.groupUUID'),
          this.props.match.params.ext
        )
      ) {
        return;
      }
      const data = extend(extensionData, downloads, {
        userExtension: this.props.match.params.ext,
      });
      this.setState({ loading: false, data });
    } catch (e) {
      console.error(e);
      this.displayExtensionNotFoundMessage();
    }
  }

  async init() {
    const countryCode = { ...this.context };
    const url = new URL(`${process.env.REACT_APP_BASE_URL}/getAuth/`);
    const urlSegments = window.location.pathname.split('/');
    const lastSegment = urlSegments[urlSegments.length - 1];
    if (parseInt(lastSegment, 10)) {
      url.searchParams.set('extension', lastSegment);
    }
    const urlParams = window.location.search;
    const searchParams = new URLSearchParams(urlParams);
    if (searchParams.get('appCheck') === 'true') {
      url.searchParams.set('appCheck', 'true');
    }

    try {
      const response = await axios.get(url.href, { withCredentials: true });
      const data = response && response.data;
      const extension = data.extension ? data.extension : false;
      if (extension && data.redirectToLauncher) {
        const sourceValue = searchParams.get('source');
        const qsOut = _compact([
          `ext=${encodeURIComponent(extension)}`,
          `lang=${encodeURIComponent(countryCode)}`,
          sourceValue && `source=${encodeURIComponent(sourceValue)}`,
        ]).join('&');
        const currentLocation = window.location.hostname.split('.');
        const currentEnvironment = currentLocation
          .slice(currentLocation.length - 2)
          .join('.');
        const launcherURL = `https://launch.${currentEnvironment}/`;
        const fullLauncherUrl = `${launcherURL}?${qsOut}`;
        window.location.href = fullLauncherUrl;
      } else if (extension && data.redirectToWebApp) {
        const webappUrl = process.env.REACT_APP_WEBAPP_URL;
        const fullWebAppUrl = `${webappUrl}/join/${encodeURIComponent(
          extension
        )}`;
        window.location.href = fullWebAppUrl;
      } else {
        this.renderApp();
      }
    } catch (e) {
      this.renderApp();
    }
  }

  render() {
    if (this.state.loading) {
      const spinConf = {
        length: 20,
        width: 10,
        radius: 22,
        position: 'absolute',
      };
      return <ReactSpinner config={spinConf} />;
    }

    if (
      this.state.notFound ||
      (!this.state.loading && this.state.data && !this.state.data.userExtension)
    ) {
      return <Redirect to="/notFound" />;
    }

    if (
      !this.state.loading &&
      this.state.data &&
      this.state.data.userExtension &&
      this.state.hasCheckedLogin
    ) {
      // eslint-disable-next-line
      analytics.track('landed-CallMe', {
        groupUUID: this.state.data.extensionMetadata.groupUUID,
        callExtension: this.state.data.userExtension,
      });
      return (
        <div className={classes.container}>
          <Header />
          <div className={classes.content} id="mainContent">
            <div className={classes.callMeContent}>
              {!browserInfo.isTablet && !browserInfo.isMobile ? (
                <DesktopCallout extension={this.props.match.params.ext} />
              ) : null}

              {browserInfo.isWebAppSupported ? (
                <RTCComp
                  show={this.state.show}
                  extension={this.props.match.params.ext}
                  data={this.state.data}
                  galaxy={this.state.data.extensionMetadata.galaxy}
                />
              ) : (
                this.getOptionsComponent()
              )}
              <Footer />
            </div>
          </div>
        </div>
      );
    }
    return <div />;
  }
}

BaseLayout.propTypes = {
  match: PT.object.isRequired,
  location: PT.object.isRequired,
};

BaseLayout.contextTypes = {
  messages: PT.object,
};
