import React from 'react';
import { PropTypes as PT } from 'prop-types';
import baseClasses from 'styles/CallMe/baseLayout.module.scss';
import Header from 'components/Common/header';
import Footer from 'components/Common/footer';
import browserInfo from 'helpers/browser-utils';
import WebContent from 'components/Downloads/webContent';
import MobileContent from 'components/Downloads/mobileContent';
import TabletContent from 'components/Downloads/tabletContent';
import analytics from 'helpers/analytics';

class BaseLayout extends React.Component {
  componentDidMount() {
    analytics.track('landed-Downloads');
  }

  shouldComponentUpdate() {
    return false;
  }

  getComponent() {
    if (browserInfo.isTablet) {
      if (browserInfo.isIOS) {
        return <TabletContent />;
      } else if (browserInfo.isAndroid) {
        return <TabletContent isAndroid />;
      }
    } else if (browserInfo.isMobile) {
      if (browserInfo.isIOS) {
        return <MobileContent />;
      } else if (browserInfo.isAndroid) {
        return <MobileContent isAndroid />;
      }
    }
    return <WebContent />;
  }

  render() {
    return (
        <div className={baseClasses.container}>
          <Header hideLogin />
          <div className={baseClasses.content}>
            {this.getComponent()}
          </div>
          <Footer
            isMobile={browserInfo.isMobile && (browserInfo.isIOS || browserInfo.isAndroid)}
          />
        </div>
    );
  }
}

BaseLayout.contextTypes = {
  messages: PT.object,
};

export default BaseLayout;
