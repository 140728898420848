import React from 'react';
import { Switch, Route } from 'react-router-dom';
import DownloadsLayout from 'components/Downloads/layout';
import NumbersLayout from 'components/Numbers/layout';
import OtherWaysLayout from 'components/OtherWays/layout';
import GenericNotFound from 'components/Common/GenericNotFound';
import ExtensionNotFound from 'components/Common/ExtensionNotFound';
import 'normalize.css';
import 'styles/global.scss';
import { BaseLayout } from './CallMe/layout';


const Router = () => (
  <Switch>
    <Route exact path="/:galaxy(pstn)/numbers" component={NumbersLayout} />
    <Route exact path="/:galaxy(pstn)/numbers/:custom(mlb|bamtech|bam)" component={NumbersLayout} />
    <Route exact path="/numbers" component={NumbersLayout} />
    <Route exact path="/numbers/:custom(mlb|bamtech|bam)" component={NumbersLayout} />
    <Route exact path="/download" component={DownloadsLayout} />
    <Route exact path="/downloads" component={DownloadsLayout} />
    <Route exact path="/otherways/:ext(\d+)" component={OtherWaysLayout} />
    <Route exact path="/extension/:ext(\d+)" component={BaseLayout} />
    <Route exact path="/call/:ext(\d+)" component={BaseLayout} />
    <Route exact path="/:ext(\d+)" component={BaseLayout} />
    <Route exact path="/notFound" component={ExtensionNotFound} />
    <Route path="/.well-known/apple-app-site-association" onEnter={() => {window.location.reload()}} />
    <Route path="*" component={GenericNotFound} />
  </Switch>
);

export default Router;
