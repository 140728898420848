import MixPanel from 'mixpanel-browser';

class Analytics {
  constructor(mixpanel) {
    this.mixpanel = mixpanel;
  }

  init(apiKey) {
    try {
      this.mixpanel.init(apiKey);
      this.reset();
    } catch (ex) {
      // console.warn(`Unable to initialize mixpanel with api key: ${ex}`);
    }
  }

  track(eventName, properties) {
    try {
      this.mixpanel.track(eventName, properties);
    } catch (ex) {
      // console.warn(`Error calling this.mixpanel.track: ${ex}`);
    }
  }

  reset() {
    try {
      this.mixpanel.reset();
    } catch (ex) {
      // console.warn(`Unable to reset mixpanel: ${ex}`);
    }
  }
}

const token = 'a12b40d382e14bbf23164ec7dc1eaa6b';
const analytics = new Analytics(MixPanel);
analytics.init(token);

export default analytics;
