import React from 'react';
import Translate from 'components/Common/translate';
import classes from 'styles/CallMe/nameForm.module.scss';
import * as querystring from 'query-string';
import analytics from 'helpers/analytics';
import PropTypes from 'prop-types';

const validateNameLength = (name) => {
  name = name.trim();
  if (name === '' || name === null) return null;
  const length = name.length;
  return length < 33;
};

const isEmailValid = (email) => {
  if (email === '' || email === null) return true;
  // eslint-disable-next-line
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

class NameForm extends React.Component {
  static get propTypes() {
    return {
      extension: PropTypes.string.isRequired,
      groupUUID: PropTypes.string.isRequired,
      galaxy: PropTypes.bool.isRequired,
    }
  }

  static get contextTypes() {
    return {
      messages: PropTypes.object,
    }
  }

  constructor() {
    super();
    this.state = {
      name: {
        value: '',
        isValid: null,
      },
      emailAddressObject: {
        value: '',
      },
      cachedName: '',
      cachedEmailAddress: '',
    };

    this.cachedNameKey = 'callme.nameform.localuser';
    this.cachedEmailAddressKey = 'callme.nameform.emailAddress';
  }

  componentDidMount() {
    // On mount of the component, pull up the locally cached username and email address
    const cachedName = localStorage.getItem(this.cachedNameKey);
    const cachedEmailAddress = localStorage.getItem(this.cachedEmailAddressKey);

    // Initialize the state with the cached data if it exists.
    if (cachedName) {
      const nameCopy = this.createNewNameObject(cachedName);
      this.setState({ cachedName: nameCopy.value });
      this.setState({ name: nameCopy });
    }

    if (cachedEmailAddress) {
      const emailAddressCopy = { ...this.state.emailAddressObject };
      emailAddressCopy.value = cachedEmailAddress;
      this.setState({ cachedEmailAddress: emailAddressCopy.value });
      this.setState({ emailAddressObject: emailAddressCopy });
    }
  }

  createNewNameObject(newName) {
    const nameCopy = { ...this.state.name };
    nameCopy.value = newName;
    nameCopy.isValid = validateNameLength(nameCopy.value);
    return nameCopy;
  }

  handleNameChange(event) {
    const newName = event.target.value;
    const nameCopy = this.createNewNameObject(newName);

    this.setState({ name: nameCopy });
  }

  handleEmailAddressChange(event) {
    const newEmailAddressValue = event.target.value;
    const emailAddressCopy = { ...this.state.emailAddressObject };
    emailAddressCopy.value = newEmailAddressValue;

    this.setState({ emailAddressObject: emailAddressCopy });
  }

  removeEmoji(stringToTransform) {
    return stringToTransform.replace(/(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi, '');
  }

  handleSubmit(event) {
    const { extension, groupUUID, galaxy } = this.props;
    const { cachedName, cachedEmailAddress } = this.state;

    const name = this.removeEmoji(this.state.name.value);
    if (name !== '') {
      localStorage.setItem(this.cachedNameKey, name);
    }

    const email = this.removeEmoji(this.state.emailAddressObject.value);
    if (email !== '') {
      localStorage.setItem(this.cachedEmailAddressKey, email);
    }

    const currentTimestamp = new Date();
    const returningGuest = (name !== '' && cachedName === name) || (cachedEmailAddress !== '' && cachedEmailAddress === email);
    const guestInfo = {
      name,
      email,
      callExtension: extension,
      groupUUID,
      currentTimestamp,
      returningGuest,
    };
    analytics.track('GuestCall-CallMePage', { groupUUID: guestInfo.groupUUID, callExtension: guestInfo.callExtension, guestName: guestInfo.name, guestEmail: guestInfo.email});
    const q = querystring.stringify(guestInfo);
    window.location = `https://${galaxy ? process.env.REACT_APP_GALAXY_ENV_URL : process.env.REACT_APP_ENV_URL}/join/${extension}/${groupUUID}?${q}`;
    event.preventDefault();
  }

  submitButtonDisabled() {
    const { name, emailAddressObject } = this.state;
    return (!name.isValid || !isEmailValid(emailAddressObject.value));
  }

  render() {
    const { name, emailAddressObject } = this.state;
    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <Translate id={'nameFormHeader'} />
        </div>
        <div className={classes.subheader}>
          <Translate id={'nameFormSubHeader'} />
        </div>
        <form onSubmit={(event)=> {this.handleSubmit(event)}}>
          <input placeholder={this.context.messages.nameFormPlaceholder} type="text" onChange={(event) => {this.handleNameChange(event)}} value={name.value} />
          <div className={classes.labelContainer}>
            <Translate id="nameWillBeSeenWarning" className={classes.labelPlaceholder} />
          </div>
          {
            validateNameLength(name.value) === false && (
              <div className={classes.error}>
                <Translate id="nameFormLengthError" />
              </div>
            )
          }
          <input
            className={classes.inputSpacing}
            placeholder={this.context.messages.workEmailFormPlaceholder}
            type="text"
            onChange={(event) => {this.handleEmailAddressChange(event)}}
            value={emailAddressObject.value}
          />
          <div className={classes.labelContainer}>
            <Translate id="emailWontBeSharedWarning" className={classes.labelPlaceholder} />
          </div>
          {
            !isEmailValid(emailAddressObject.value) && (
              <div className={classes.error}>
                <Translate id="emailFormatUnrecognizedError" />
              </div>
            )
          }
          <button
            type="submit"
            disabled={this.submitButtonDisabled()}
          >
            <Translate id={'next'} />
          </button>

        </form>
        <div className={classes.guidance}>
          <Translate id={'nameFormGuidance'} />
        </div>
      </div>
    );
  }
}


export default NameForm;
