import React from 'react';
import { PropTypes as PT } from 'prop-types';
import baseClasses from 'styles/CallMe/baseLayout.module.scss';
import Header from 'components/Common/header';
import Footer from 'components/Common/footer';
import Translate from 'components/Common/translate';
import classes from 'styles/Common/ExtensionNotFound.module.scss';
import buddy from 'images/Buddy-Mark.svg';
import analytics from 'helpers/analytics';

class GenericNotFound extends React.PureComponent {
  componentDidMount() {
    analytics.track('landed-404');
  }
  render() {
    return (
        <div className={baseClasses.container}>
          <Header hideLogin />
          <div className={baseClasses.content}>
            <div className={classes.container}>
              <div className={classes.content}>
                <img src={buddy} alt="Lifesize" />
                <div className={classes.header}>
                  <Translate id={'genericNotFoundHeader'} />
                </div>
                <div className={classes.subHeader}>
                  <Translate id={'genericNotFoundSubHeader1'} />
                </div>
                <div className={classes.subHeader}>
                  <Translate id={'genericNotFoundSubHeader2'} />
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
    );
  }
}

GenericNotFound.contextTypes = {
  messages: PT.object,
};

export default GenericNotFound;
