import React from 'react';
import { PropTypes as PT } from 'prop-types';
import Translate from 'components/Common/translate';
import classes from 'styles/CallMe/desktopCallout.module.scss';
import icons from 'styles/customFont.module.css';

const DesktopCallout = ({ extension }) => (
  <div className={classes.container}>
    <a
      className={classes.lifesizeDesktop}
      href={`lifesize://${extension}@lifesizecloud.com;guest=`}
      rel="noopener noreferrer"
    >
      <i className={icons.iconBuddy} />
      <span className={classes.calloutText}>
        <Translate id={'desktopCalloutTop'} />
        <br />
        <span className={classes.bold}>
          <Translate id={'desktopCalloutBottom'} />
        </span>
      </span>
    </a>
  </div>
);

DesktopCallout.propTypes = {
  extension: PT.string.isRequired,
};

export default DesktopCallout;

