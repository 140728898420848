import browserInfo from './browser-utils';
import axios from 'axios';
import _get from 'lodash/get';

const guestFlowFeatureFlag = 'CALLME_NewGuestFlow';
const webAppGuestFlow = 'CALLME_WebAppGuestFlow';
const featureFlagApiConfig = {
  headers: { 'x-api-key': process.env.REACT_APP_FEATURE_FLAG_SERVICE_API_KEY }
};

async function getExtensionData(extension) {
  const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/fetch/getAll/${extension}`);
  return response.data;
}

async function getGuestFlowFeatureFlags(groupId) {
  if (!groupId) {
    throw new Error('Missing parameter: groupId');
  }
  const url = new URL(process.env.REACT_APP_FEATURE_FLAG_SERVICE_URL);
  url.searchParams.set('guest', 'true');
  url.searchParams.set('featureFlags', `${guestFlowFeatureFlag},${webAppGuestFlow}`);
  url.searchParams.set('groupId', groupId);
  const response = await axios.get(url.href, featureFlagApiConfig);
  return _get(response, ['data']);
}

async function redirectIfSupported(groupId, extension) {
  const featureFlags = await getGuestFlowFeatureFlags(groupId);
  if (groupId && extension && browserInfo.isNewGuestFlowSupported && (featureFlags[guestFlowFeatureFlag] || featureFlags[webAppGuestFlow])) {
    let guestFlowUrl = `https://${process.env.REACT_APP_NEW_GUEST_FLOW}/${extension}`;
    // refer https://lifesize.atlassian.net/browse/GOLD-3554
    // Redirect only desktop users to migrated guest flow.
    if(featureFlags[webAppGuestFlow] && !browserInfo.isMobile && !browserInfo.isTablet){
      guestFlowUrl = `https://${process.env.REACT_APP_ENV_URL}/guest/${extension}`;
    }
    console.log(`Redirecting to new guest flow: ${guestFlowUrl}`);
    window.location = guestFlowUrl;
    return true;
  }
}

export default { redirectIfSupported, getExtensionData };
