import React from 'react';
import { PropTypes as PT } from 'prop-types';
import classes from 'styles/Common/header.module.scss';
import LanguageDropdown from 'components/Common/languageDropdown';
import icons from 'styles/customFont.module.css';

class Header extends React.PureComponent {
  render() {
    return (
      <header className={classes.container}>
        <i className={`${icons.iconLsLogo} ${classes.logo}`} />
        <div className={classes.rightSide}>
          <a href={`https://www.lifesize.com/${(this.context.countryCode === 'zh') ? 'zh-Hans' : this.context.countryCode}/app-help`} rel="noopener noreferrer" target="_blank">
            <i className={`${classes.question} ${icons.iconHelpOutline}`} />
          </a>
          <LanguageDropdown />
        </div>
      </header>
    );
  }
}

Header.contextTypes = {
  countryCode: PT.string,
};

Header.propTypes = {
  hideLogin: PT.bool,
};

Header.defaultProps = {
  hideLogin: false,
};

export default Header;
