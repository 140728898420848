import React from 'react';
import { PropTypes as PT } from 'prop-types';
import Translate from 'components/Common/translate';
import classes from 'styles/Common/footer.module.scss';

class Footer extends React.PureComponent {
  render() {
    return (
      <footer className={`${classes.container} ${(this.props.isMobile) ? classes.isMobile : ''}`}>
        <Translate
          id={'footer'}
          replaceText={`${new Date().getFullYear()}`}
          replaceVariable={'date'}
        />
        &nbsp;
        <a
          href={'https://www.lifesize.com/en/company/legal-notices/privacy'}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Translate id={'footerPolicy'} />
        </a>
        &nbsp; | &nbsp;
        <a
          href={'https://www.lifesize.com/~/media/Documents/Other%20Documents/Legal%20Compliance/End%20User%20License%20Agreements/Lifesize%20Cloud%20Terms%20of%20Service%20TOS.ashx?la=en'}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Translate id={'footerTerms'} />
        </a>
      </footer>
    );
  }
}

Footer.propTypes = {
  isMobile: PT.bool,
};

Footer.defaultProps = {
  isMobile: false,
};

export default Footer;
