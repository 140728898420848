import browserDetector from 'bowser';

const browserInfo = {
  chrome: false,
  chromium: false,
  detectedVersion: undefined,
  downloadsURL: 'https://call.lifesizecloud.com/download',
  edge: false,
  firefox: false,
  IE: false,
  opera: false,
  safari: false,
  undefined: false,
  isNewGuestFlowSupported: false
};

export const browserUrls = {
  chrome: 'https://www.google.com/chrome/browser/desktop/index.html',
  firefox: 'https://www.mozilla.org/en-US/firefox/new/',
  edge: 'https://www.microsoft.com/en-us/windows/microsoft-edge',
  ie: 'https://www.microsoft.com/en-us/download/internet-explorer.aspx',
  safari: 'https://support.apple.com/downloads/safari'
};

function isWebAppSupported() {
  if (browserInfo.isMobile || browserInfo.isTablet) {
    return false;
  }
  return browserInfo.chrome || browserInfo.chromium;
}

function isNewGuestFlowSupported() {
  return browserInfo.chrome || browserInfo.chromium || browserInfo.firefox || browserInfo.safari;
}

const {
  chrome,
  msedge,
  firefox,
  msie,
  opera,
  safari,
  ios,
  android,
  mobile,
  tablet,
  mac,
  windows,
  chromium
} = browserDetector;

browserInfo.chromium = Boolean(chromium);
browserInfo.chrome = Boolean(chrome);
browserInfo.edge = Boolean(msedge);
browserInfo.firefox = Boolean(firefox);
browserInfo.IE = Boolean(msie);
browserInfo.opera = Boolean(opera);
browserInfo.safari = Boolean(safari);
browserInfo.undefined = !(chrome || msedge || firefox || msie || opera || safari);
browserInfo.detectedVersion = browserInfo.undefined
  ? undefined
  : (parseFloat(browserDetector.version) || undefined);


browserInfo.isMobile = Boolean(mobile);
browserInfo.isTablet = Boolean(tablet);
browserInfo.isIOS = Boolean(ios);
browserInfo.isAndroid = Boolean(android);
browserInfo.isWebAppSupported = isWebAppSupported();
browserInfo.mac = Boolean(mac);
browserInfo.windows = Boolean(windows);
browserInfo.isNewGuestFlowSupported = isNewGuestFlowSupported();

export default browserInfo;
