import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { render } from 'react-dom';
import Router from 'components/router';
import { BrowserRouter } from 'react-router-dom';
import InternationalizationProvider from 'providers/internationalization';
import { Localization, localizationUtils } from '@lifesize/clients.localization';
import ls from 'local-storage';
import Promise from 'promise-polyfill';
import Cookies from 'universal-cookie';
// To add to window
if (!window.Promise) {
  window.Promise = Promise;
}

const cookies = new Cookies();

const callLang = (cookies.get('call-lang')) ? cookies.get('call-lang').substring(0, 2) : null;

const language =
  callLang ||
  ls.get('userLanguage') || // had to leave this here as we deployed with it.
  (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  navigator.userLanguage;

if (!callLang) {
  cookies.set('call-lang', language, {
    domain: window.location.hostname.substring(window.location.hostname.lastIndexOf(".", window.location.hostname.lastIndexOf(".") - 1) + 1),
    path: '/',
  });
}

const localeData = {
  'cs': require('@lifesize/strings/services.callme/cs.json'),
  'de': require('@lifesize/strings/services.callme/de.json'),
  'en': require('@lifesize/strings/services.callme/en.json'),
  'es': require('@lifesize/strings/services.callme/es.json'),
  'fi': require('@lifesize/strings/services.callme/fi.json'),
  'fr': require('@lifesize/strings/services.callme/fr.json'),
  'it': require('@lifesize/strings/services.callme/it.json'),
  'ja': require('@lifesize/strings/services.callme/ja.json'),
  'ko': require('@lifesize/strings/services.callme/ko.json'),
  'nl': require('@lifesize/strings/services.callme/nl.json'),
  'no': require('@lifesize/strings/services.callme/no.json'),
  'pl': require('@lifesize/strings/services.callme/pl.json'),
  'pt': require('@lifesize/strings/services.callme/pt.json'),
  'sv': require('@lifesize/strings/services.callme/sv.json'),
  'zh': require('@lifesize/strings/services.callme/zh.json'),
  'zh-Hant': require('@lifesize/strings/services.callme/zh-Hant.json'),
  'ru': require('@lifesize/strings/services.callme/ru-RU.json')
};

const languageId = localizationUtils.getLanguageId(language);
const localization = new Localization({ id: languageId });

const countryCode = localization.language;

const renderApp = () => {
  const messages = localeData[countryCode] || localeData[language] || localeData.en;

  render(
    <BrowserRouter>
      <InternationalizationProvider messages={messages} countryCode={countryCode} localization={localization}>
        <Router />
      </InternationalizationProvider>
    </BrowserRouter>,
    document.getElementById('root'),
  );
}

renderApp();