const ALL_NUMBERS_BASE_URL = '/numbers';
const ALL_GALAXY_NUMBERS_BASE_URL = '/pstn/numbers';

const groupUUIDToShortName = (groupUUID) => {
  switch (groupUUID) {
    case process.env.REACT_APP_MLB_UUID:
      return 'mlb';
    case process.env.REACT_APP_BAM_UUID:
      return 'bamtech';
    default:
      return '';
  }
};

const getAvailableNumbersURL = (groupUUID, galaxy = false) => {
  const groupName = groupUUIDToShortName(groupUUID);
  const urlPostfix = groupName ? `/${groupName}` : '';

  // galaxy users get directed to a special galaxy numbers page
  if (galaxy) {
    return `${ALL_GALAXY_NUMBERS_BASE_URL}${urlPostfix}`;
  }

  // legacy users go to our old legacy page
  return `${ALL_NUMBERS_BASE_URL}${urlPostfix}`;
};

export default getAvailableNumbersURL;
