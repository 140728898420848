import React from 'react';
import { PropTypes as PT } from 'prop-types';
import macApp from 'images/ls-mac-app.svg';
import windows from 'images/windows.svg';
import macStore from 'images/ls-mac-app-copy.svg';
import google from 'images/google-play.svg';
import _find from 'lodash/find';
import _get from 'lodash/get';
import _map from 'lodash/map';
import Translate from 'components/Common/translate';
import classes from 'styles/CallMe/callingOptions.module.scss';
import icons from 'styles/customFont.module.css';
import browserInfo from 'helpers/browser-utils';
import getAvailableNumbersURL from 'helpers/urlHelper';

const CallingOptions = ({ hideHeader, data, galaxy }) => {
  const { phoneNumberData, thirdPartyEnabled, defaultPhoneNumber } = data;
  const phones = _get(phoneNumberData, 'list') || [];
  const prefs = _get(phoneNumberData, 'preferences') || [];

  const directDialString = data.sipDialString;
  // HACK ALERT: do this in a less hacky way the next time someone refactors this
  //             lync needs to point directly to lifesizecloud.com
  const lyncDialString = `sip:${data.sipDialString.replace('sip.', '')}`;

  return (
    <div className={classes.container}>
      <div className={classes.dialingOptions}>
        {
          (!hideHeader) ?
            <div className={classes.header}>
              <Translate id={'waysToCallIntoMeeting'} />
            </div> :
            null
        }
        <div className={classes.optionRow}>
          <div className={classes.option}>
            <div className={classes.iconContainer}>
              <i className={icons.iconRoomSystem} />
            </div>
            <div className={classes.infoContainer} data-test = "roomSystem">
              <div><Translate id={'optionsLifesize'} /></div>
              <div>
                <Translate id={'optionsKeypad'} />
                <div className={classes.bold}>
                  {data.userExtension}
                </div>
              </div>
            </div>
          </div>
          {
            (phones.length) ?
              <div className={classes.option}>
                <div className={classes.iconContainer}>
                  <i className={icons.iconAudioCallPstn} />
                </div>
                <div className={classes.infoContainer} data-test = "phoneDetails">
                  <div><Translate id={'phone'} /></div>
                  <div className={classes.numbers}>
                    {
                      (phones.length && prefs.length) ?
                        _map(prefs, (phonePreference) => {
                          const phone = _find(phones, { id: phonePreference });
                          const countryCode = _get(phone, 'countryCode', 'US');
                          const phoneNumber = _get(phone, 'phoneNumber', defaultPhoneNumber);
                          return (
                            <div key={countryCode}>
                              <span className={classes.label}>
                                <Translate id={countryCode} appendedText=": " />
                              </span>
                              <span className={classes.value}>{phoneNumber}</span>
                            </div>
                          );
                        }) :
                        <div>
                          <span className={classes.label}>
                            <Translate id='US' appendedText=": " />
                          </span>
                          <span className={classes.value}>{defaultPhoneNumber}</span>
                        </div>
                    }
                    <div>
                      <span className={classes.label} data-test = "extensionString"><Translate id={'optionsEnterExtension'} /></span>
                      <span className={classes.value} data-test = "extension">{data.userExtension}</span>
                    </div>
                  </div>
                  <br />
                  <a href={getAvailableNumbersURL(data.extensionMetadata.groupUUID, galaxy)} rel="noopener noreferrer" target="_blank">
                    <Translate id={'optionsAdditional'} />
                  </a>
                </div>
              </div> : null}
        </div>
        <div className={classes.optionRow}>
          <div className={classes.option}>
            <div className={classes.iconContainer}>
              <i className={icons.iconMeeting} />
            </div>
            <div className={classes.infoContainer} data-test = "thirdPartyRoomSystem">
              <div><Translate id={'optionsThirdParty'} /></div>
              <div>
                <span className={classes.label}><Translate id={'optionsIP'} /> </span>
                <span className={classes.value}>{data.cssTargetIP}</span>
              </div>
              <div>
                <span className={classes.label} data-test = "extensionString"><Translate id={'optionsEnterExtension'} /> </span>
                <span className={classes.value} data-test = "extension">{data.userExtension}</span>
              </div>
              <br />
              <div>
                <span className={classes.label}><Translate id={'optionsDirect'} /> </span>
                <span className={classes.value} data-test = "dialString">{directDialString}</span>
              </div>
            </div>
          </div>
          {
            thirdPartyEnabled ?
              <div className={classes.option}>
                <div className={classes.iconContainer}>
                  <i className={icons.iconLaptop} />
                </div>

                <div className={classes.infoContainer} data-test = "thirdPartyApps">
                  <div><Translate id={'optionsThirdPartyApps'} /></div>
                  <div><Translate id={'optionsLaunch'} /></div>
                  <br />
                  <div className={classes.thirdParty}>
                    <a href={lyncDialString} rel="noopener noreferrer">
                    <div className={classes.skype} data-test = "skypeOption" />
                    </a>
                  </div>
                  <br />
                  <div className={classes.thirdParty}>
                  <div className={classes.lync} data-test = "lyncOption" />
                    <a href={lyncDialString} rel="noopener noreferrer">
                      <span><Translate id={'optionsLync'} /></span>
                    </a>
                  </div>
                </div>
              </div>
              : null
          }
        </div>
        <div className={classes.downloadLinks}>
          {
            browserInfo.mac ?
              <a href={data.osxDownloadURI} rel="noopener noreferrer">
                <img alt="" className={classes.macApp} src={macApp} />
              </a> :
              <a href={data.windowsDownloadURI} rel="noopener noreferrer">
                <img alt="" className={classes.windows} src={windows} />
              </a>
          }
          <a href={data.iOSDownloadURI} rel="noopener noreferrer" target="_blank">
            <img alt="" className={classes.macStore} src={macStore} />
          </a>
          <a href={data.androidDownloadURI} rel="noopener noreferrer" target="_blank">
            <img alt="" className={classes.google} src={google} />
          </a>
        </div>
      </div>
    </div>
  );
};

CallingOptions.propTypes = {
  hideHeader: PT.bool,
  data: PT.object.isRequired,
  galaxy: PT.bool.isRequired,
};

CallingOptions.defaultProps = {
  hideHeader: false,
  galaxy: false,
};

export default CallingOptions;
