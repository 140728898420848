import React from 'react';
import { PropTypes as PT } from 'prop-types';
import { localizationConstants } from '@lifesize/clients.localization';
import classes from 'styles/Common/languageDropdown.module.scss';
import _map from 'lodash/map';
import enhanceWithClickOutside from 'react-click-outside';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

class LanguageDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
    };
  }

  handleToggle() {
    this.setState({
      isExpanded: !this.state.isExpanded,
    });
  }

  handleClickOutside() {
    this.setState({
      isExpanded: false,
    });
  }

  handleSelectLanguage(code) {
    cookies.set('call-lang', code, {
      domain: window.location.hostname.substring(window.location.hostname.lastIndexOf(".", window.location.hostname.lastIndexOf(".") - 1) + 1),
      path: '/',
    });
    this.setState({
      isExpanded: false,
    });
    window.location.reload();
  }

  render() {
    const languages = localizationConstants.supportedLanguages;
    const localization = this.context.localization;

    return (
      <div className={classes.container}>
        {/*  eslint-disable-next-line */}
        <a
          href="#"
          onClick={(event) => {
            this.handleToggle();
            event.preventDefault();
          }}
        >
          {localization.languageDisplayName}
          <div className={classes.arrowDown} />
        </a>
        {
          (this.state.isExpanded) ?
            <div className={classes.dropdown}>
              <ul>
                {
                  _map(languages, language => (
                    <li key={language.id}>
                      {/*  eslint-disable-next-line */}
                      <a href="#" onClick={() => this.handleSelectLanguage(language.language)}>
                        {language.displayName}
                      </a>
                    </li>
                  ))
                }
              </ul>
            </div> :
            null
        }
      </div>
    );
  }
}

export default enhanceWithClickOutside(LanguageDropdown);

LanguageDropdown.contextTypes = {
  countryCode: PT.string,
  localization: PT.object,
};
