import React from 'react';
import { PropTypes as PT } from 'prop-types';
import baseClasses from 'styles/CallMe/baseLayout.module.scss';
import classes from 'styles/Numbers/layout.module.scss';
import Header from 'components/Common/header';
import Footer from 'components/Common/footer';
import browserInfo from 'helpers/browser-utils';
import Translate from 'components/Common/translate';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _pickBy from 'lodash/pickBy';
import _trim from 'lodash/trim';
import { translateMessage } from 'components/Common/translate-util';
import ReactSpinner from 'react-spinjs-fix';
import analytics from 'helpers/analytics';
import axios from 'axios';

class NumbersLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortedData: {},
      filteredData: {},
      filterString: '',
      loading: true,
    };
  }

  componentWillMount() {
    this.init();
  }

  componentDidMount() {
    analytics.track('landed-Numbers');
  }

  getUUID(customFlag) {
    if (customFlag) {
      const flag = customFlag.toLowerCase();
      if (flag === 'mlb') {
        return process.env.REACT_APP_MLB_UUID;
      } else if (flag === 'bamtech' || flag === 'bam') {
        return process.env.REACT_APP_BAM_UUID;
      }
    }
    return '';
  }

  isGalaxy(galaxy) {
    return (galaxy === 'pstn');
  }

  init() {
    const self = this;
    // Check to see if this is a special UUID
    const customFlag = _get(this.props.match.params, 'custom', '');
    const uuid = this.getUUID(customFlag);
    const galaxy = this.isGalaxy(_get(this.props.match.params, 'galaxy', ''));

    const pstnServiceUrl = `${process.env.REACT_APP_PSTN_BASE_URL}/phoneNumbers/all`;
    const params = {};
    if (uuid.length) {
      params.groupUuid = uuid;
    }
    if (galaxy) {
      params.galaxy = galaxy;
    }
    axios.get(pstnServiceUrl, { params }).then((response) => {
      const sortedData =
        self.sortGroupedNumbers(this.groupNumbers(response.data));
      self.setState({
        loading: false,
        sortedData,
        filteredData: sortedData,
      },
      null);
    }).catch(() => {
      self.setState({
        loading: false,
        sortedData: [] },
      null);
    });
  }

  groupNumbers(data) {
    const groupedNumbers = {};
    const countryNumbersList = _get(data, 'list', null);
    if (countryNumbersList) {
      countryNumbersList.forEach((numbersObj) => {
        const countryDisplayName = translateMessage(numbersObj.countryCode, this.context.messages);
        if (groupedNumbers[countryDisplayName]) {
          groupedNumbers[countryDisplayName].numbers.push(numbersObj.phoneNumber);
        } else {
          groupedNumbers[countryDisplayName] = numbersObj;
          groupedNumbers[countryDisplayName].numbers = [numbersObj.phoneNumber];
        }
      });
    }
    return groupedNumbers;
  }

  /**
   * Sorts phone #s alpha by display name
   * @param groupedNumbers
   */
  sortGroupedNumbers(groupedNumbers) {
    const ordered = {};
    Object.keys(groupedNumbers).sort().forEach((key) => {
      ordered[key] = groupedNumbers[key];
    });
    return ordered;
  }

  handleOnKeyPress(e) {
    this.setState({ filterString: _trim(e.target.value) }, null);
    this.setState({
      filteredData: this.filterGroupedNumbers(this.state.sortedData, _trim(e.target.value)),
    }, null);
  }

  filterGroupedNumbers(groupedNumbers, filterString) {
    if (filterString) {
      return _pickBy(groupedNumbers, (value, key) => (
        key.toLowerCase().includes(filterString.toLowerCase())
      ));
    }
    return groupedNumbers;
  }

  render() {
    if (this.state.loading) {
      const spinConf = {
        length: 20,
        width: 10,
        radius: 22,
        position: 'absolute',
      };
      return (
        <ReactSpinner
          config={spinConf}
        />
      );
    }

    return (
        <div className={baseClasses.container}>
          <Header hideLogin />
          <div className={baseClasses.content}>
            <div className={classes.container}>
              <div className={classes.content}>
                <div className={classes.header}>
                  <Translate id="additionalNumbers" />
                </div>
                <div className={classes.subTitle}>
                  <Translate id="additionalNumbersSubTitle" />
                </div>
                <div className={classes.disclaimer}>
                  <Translate id="disclaimerSubTitle" />
                </div>
                <div className={classes.searchRow}>
                  <input
                    onChange={(event)=>{this.handleOnKeyPress(event)}}
                    type="text"
                    className={classes.searchBox}
                    placeholder={this.context.messages.search}
                  />
                </div>
                <div className={classes.headerRow}>
                  <Translate id="country" />
                  <Translate id="number" />
                </div>
                {
                  (Object.keys(this.state.filteredData).length) ?
                    _map(this.state.filteredData, countryObj => (
                      <div
                        className={classes.countryRow}
                        key={countryObj.countryCode + classes.number}
                      >
                        <Translate id={countryObj.countryCode} />
                        <div className={classes.number}>
                          {
                            _map(countryObj.numbers, number => (
                              <div key={number}>{number}</div>
                            ))
                          }
                        </div>
                      </div>
                    )) :
                    <Translate id="noResults" />
                }
              </div>
              <Footer
                isMobile={browserInfo.isMobile && (browserInfo.isIOS || browserInfo.isAndroid)}
              />
            </div>
          </div>
        </div>
    );
  }
}

NumbersLayout.propTypes = {
  match: PT.object,
};

NumbersLayout.defaultProps = {
  match: '',
};

NumbersLayout.contextTypes = {
  messages: PT.object,
};

export default NumbersLayout;

