import React from 'react';
import { PropTypes as PT } from 'prop-types';
import browserInfo from 'helpers/browser-utils';
import macApp from 'images/ls-mac-app-copy.svg';
import google from 'images/google-play.svg';
import _find from 'lodash/find';
import _get from 'lodash/get';
import _map from 'lodash/map';
import Translate from 'components/Common/translate';
import classes from 'styles/CallMe/callingOptionsMobile.module.scss';
import icons from 'styles/customFont.module.css';
import getAvailableNumbersURL from 'helpers/urlHelper';

class CallingOptionsMobile extends React.PureComponent {
  render() {
    const { galaxy } = this.props;
    const { phoneNumberData, thirdPartyEnabled, defaultPhoneNumber } = this.props.data;
    const phones = _get(phoneNumberData, 'list') || [];
    const prefs = _get(phoneNumberData, 'preferences') || [];

    const directDialString = this.props.data.sipDialString;
    // HACK ALERT: do this in a less hacky way the next time someone refactors this
    //             lync needs to point directly to lifesizecloud.com
    const lyncDialString = `sip:${this.props.data.sipDialString.replace('sip.', '')}`;

    return (
      <div className={classes.container}>
        <div className={classes.dialingOptions}>
          <h1><Translate id={'mobileCallMeTitle'} /></h1>
          {
            (browserInfo.isIOS) ?
              <a className={classes.appDownload} href={this.props.data.iOSDownloadURI}>
                <img alt="" className={classes.macApp} src={macApp} />
              </a> :
              <a className={classes.appDownload} href={this.props.data.androidDownloadURI}>
                <img alt="" className={classes.google} src={google} />
              </a>
          }
          <div className={classes.alreadyHaveApp}>
            <Translate id={'mobileCallMeCTA'} />
            <a
              className={classes.callLink}
              href={`https://${process.env.REACT_APP_CALL_ME_URL}/${this.props.extension}`}
              target="_self"
            >
              <Translate id={'callNow'} />
            </a>
          </div>
          <hr />
          <h3><Translate id={'wantAnotherWay'} /></h3>
          <div className={classes.optionRow}>
            {
              (phones.length) ?
                <div className={classes.option}>
                  <div className={classes.iconContainer}>
                    <i className={icons.iconAudioCallPstn} />
                  </div>
                  <div className={classes.infoContainer}>
                    <div><Translate id={'phone'} /></div>
                    <div className={classes.numbers}>
                      {
                        (phones.length && prefs.length) ?
                          _map(prefs, (phonePreference) => {
                            const phone = _find(phones, { id: phonePreference });
                            const countryCode = _get(phone, 'countryCode', 'US');
                            const phoneNumber = _get(phone, 'phoneNumber', defaultPhoneNumber);

                            return (
                              <div key={countryCode}>
                                <span className={classes.label}>
                                  <Translate id={countryCode} appendedText=": " />
                                </span>
                                <span className={classes.value}>{phoneNumber}</span>
                              </div>
                            );
                          }) :
                          <div>
                            <span className={classes.label}>
                              <Translate id='US' appendedText=": " />
                            </span>
                            <span className={classes.value}>+1 (844) 572-5683</span>
                          </div>
                      }
                      <div>
                        <span className={classes.label}><Translate id={'optionsEnterExtension'} /></span>
                        <span className={classes.value}>{this.props.data.userExtension}</span>
                      </div>
                    </div>
                    <br />
                    <a href={getAvailableNumbersURL(this.props.data.extensionMetadata.groupUUID, galaxy)} rel="noopener noreferrer" target="_blank">
                      <Translate id={'optionsAdditional'} />
                    </a>
                  </div>
                </div> : null
            }
            <div className={classes.option}>
              <div className={classes.iconContainer}>
                <i className={icons.iconRoomSystem} />
              </div>
              <div className={classes.infoContainer}>
                <div><Translate id={'optionsLifesize'} /></div>
                <div>
                  <Translate id={'optionsKeypad'} />
                  <div className={classes.bold}>
                    {this.props.data.userExtension}
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.option}>
              <div className={classes.iconContainer}>
                <i className={icons.iconMeeting} />
              </div>
              <div className={classes.infoContainer}>
                <div><Translate id={'optionsThirdParty'} /></div>
                <div>
                  <span className={classes.label}><Translate id={'optionsIP'} /> </span>
                  <span className={classes.value}>{this.props.data.cssTargetIP}</span>
                </div>
                <div>
                  <span className={classes.label}><Translate id={'optionsEnterExtension'} /> </span>
                  <span className={classes.value}>{this.props.data.userExtension}</span>
                </div>
                <br />
                <div>
                  <span className={classes.label}><Translate id={'optionsDirect'} /> </span>
                  <span className={classes.value}>{directDialString}</span>
                </div>
              </div>
            </div>
            {
              thirdPartyEnabled ?
                <div className={classes.option}>
                  <div className={classes.iconContainer}>
                    <i className={icons.iconLaptop} />
                  </div>

                  <div className={classes.infoContainer}>
                    <div><Translate id={'optionsThirdPartyApps'} /></div>
                    <div><Translate id={'optionsLaunch'} /></div>
                    <br />
                    <div className={classes.thirdParty}>
                      <a href={lyncDialString} rel="noopener noreferrer">
                        <div className={classes.skype} />
                      </a>
                    </div>
                    <br />
                    <div className={classes.thirdParty}>
                      <div className={classes.lync} />
                      <a href={lyncDialString} rel="noopener noreferrer">
                        <span><Translate id={'optionsLync'} /></span>
                      </a>
                    </div>
                  </div>

                </div>
                : null
            }
          </div>
        </div>
      </div>
    );
  }
}

CallingOptionsMobile.propTypes = {
  data: PT.object.isRequired,
  extension: PT.string.isRequired,
  galaxy: PT.bool.isRequired,
};

CallingOptionsMobile.defaultProps = {
  galaxy: false,
};

export default CallingOptionsMobile;
