import React from 'react';
import Translate from 'components/Common/translate';
import macApp from 'images/ls-mac-app.svg';
import windows from 'images/windows.svg';
import macStore from 'images/ls-mac-app-copy.svg';
import google from 'images/google-play.svg';
import classes from 'styles/Downloads/webContent.module.scss';
import downloads from 'data/downloads.json';
import browserInfo, { browserUrls } from 'helpers/browser-utils';

const WebContent = () => (
  <div className={classes.container}>
    <div className={classes.header}>
      <Translate id="downloadsHeader" />
    </div>
    <div className={classes.subHeader}>
      <Translate id="downloadsSubHeader1" />
    </div>
    <div className={classes.topContentflexWrapper}>
      <div className={classes.subSection}>
        <div className={classes.subSectionLeft}>
          <div className={classes.subSectionHeader}>
            <Translate id="downloadsSubSectionHeader1" />
          </div>
          <div className={classes.subSectionContent}>
            <Translate id="downloadsSubSectionContent1" />
          </div>
        </div>
        <div className={classes.subSectionRight}>
          {
            browserInfo.mac ?
              <a href={downloads.osxDownloadURI} rel="noopener noreferrer">
                <img alt="" className={classes.macApp} src={macApp} />
              </a> :
              <a href={downloads.windowsDownloadURI} rel="noopener noreferrer">
                <img alt="" className={classes.windows} src={windows} />
              </a>
          }
        </div>
      </div>
      <div className={classes.subSection}>
        <div className={classes.subSectionLeft}>
          <div className={classes.subSectionHeader}>
            <Translate id="downloadsSubSectionHeader2" />
          </div>
          <div className={classes.subSectionContent}>
            <Translate id="downloadsSubSectionContent2" />
          </div>
        </div>
        <div className={classes.subSectionRight}>
          <a href={downloads.iOSDownloadURI} rel="noopener noreferrer" target="_blank">
            <img alt="" className={classes.macStore} src={macStore} />
          </a>
          <a href={downloads.androidDownloadURI} rel="noopener noreferrer" target="_blank">
            <img alt="" className={classes.google} src={google} />
          </a>
        </div>
      </div>
    </div>
    <div className={classes.header}>
      <Translate id="downloadsHeader2" />
    </div>
    <div className={classes.subHeader}>
      <Translate id="downloadsSubHeader2" />
    </div>
    <div className={classes.topContentflexWrapper}>
      <div className={classes.subSection}>
        <div className={classes.subSectionLeft}>
          <div className={classes.subSectionContent}>
            <Translate id="downloadsSubSectionContent3" />
            <div className={classes.spacer}>
              <Translate id="downloadsSubSectionContent4" />
            </div>
            <div className={classes.spacer}>
              <Translate id="downloadsSubSectionContent4-1" />
            </div>
          </div>
        </div>
        <div className={`${classes.subSectionRight} ${classes.browserLogos}`}>
          <div className={classes.logoContainer}>
            <a target="_blank" rel="noopener noreferrer" href={browserUrls.chrome}>
              <div className={`${classes.browserLogo} ${classes.chrome}`} />
            </a>
            <a target="_blank" rel="noopener noreferrer" href={browserUrls.edge}>
              <div className={`${classes.browserLogo} ${classes.edge}`} />
            </a>
          </div>
          <div className={classes.logoSpacer}>
            <a target="_blank" rel="noopener noreferrer" href={browserUrls.chrome}>
              <div className={`${classes.browserLogo} ${classes.chrome}`} />
            </a>
            <a target="_blank" rel="noopener noreferrer" href={browserUrls.edge}>
              <div className={`${classes.browserLogo} ${classes.edge}`} />
            </a>
          </div>
          <div className={classes.logoSpacer}>
            <a target="_blank" rel="noopener noreferrer" href={browserUrls.safari}>
              <div className={`${classes.browserLogo} ${classes.safari}`} />
            </a>
            <a target="_blank" rel="noopener noreferrer" href={browserUrls.ie}>
              <div className={`${classes.browserLogo} ${classes.ie}`} />
            </a>
            <a target="_blank" rel="noopener noreferrer" href={browserUrls.firefox}>
              <div className={`${classes.browserLogo} ${classes.firefox}`} />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div className={classes.header}>
      <Translate id="downloadsHeader3" />
    </div>
    <div className={classes.subHeader}>
      <Translate id="downloadsSubHeader3" />
    </div>
    <div className={classes.subSection}>
      <div className={classes.subSectionLeftLower}>
        <div className={classes.subSectionHeader}>
          <Translate id="downloadsSubSectionHeader4" />
        </div>
        <div className={classes.subSectionImage}>
          <a href={downloads.outlookPluginDownloadURI} rel="noopener noreferrer" target="_blank">
            <div className={classes.outlook} />
          </a>
        </div>
      </div>
      <div className={classes.subSectionRightLower}>
        <Translate id="downloadsSubSectionContent5" />
      </div>
    </div>
    <div className={classes.subSection}>
      <div className={classes.subSectionLeftLower}>
        <div className={classes.subSectionHeader}>
          <Translate id="downloadsSubSectionHeader5" />
        </div>
        <div className={classes.subSectionImage}>
          <a href={downloads.googleWorkspaceDownloadURI} rel="noopener noreferrer" target="_blank">
            <div className={classes.googleWorkspace} />
          </a>
        </div>
      </div>
      <div className={classes.subSectionRightLower}>
        <Translate id="downloadsSubSectionContent6" />
      </div>
    </div>
  </div>
);

export default WebContent;
