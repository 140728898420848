import React from 'react';
import { PropTypes as PT } from 'prop-types';
import CallingOptions from 'components/CallMe/callingOptions';
import Translate from 'components/Common/translate';
import NameForm from 'components/CallMe/nameForm';
import classes from 'styles/CallMe/hasRTC.module.scss';
import icons from 'styles/customFont.module.css';


class HasRTC extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCallingOptions: props.show,
    };
  }

  componentDidMount() {
    if (this.props.show) {
      setTimeout(() => {
        document.getElementById('mainContent').scrollTop = document.getElementById('mainContent').scrollHeight;
        window.scrollTo(0, document.body.scrollHeight);
      }, 0);
    }
  }

  render() {
    return (
      <div className={classes.container}>
        <NameForm extension={this.props.extension} groupUUID={this.props.data.extensionMetadata.groupUUID} galaxy={this.props.galaxy} />
        {/*  eslint-disable-next-line */}
        <a
          href="#"
          onClick={
            () => {
              this.setState({ showCallingOptions: !this.state.showCallingOptions });
            }
          }
        >
          <i className={`${icons.iconMeeting} ${classes.iconMeeting}`} />
          <Translate id="waysToCallIntoMeeting" />
          <i
            className={
              (this.state.showCallingOptions) ?
                `${icons.iconChevron} ${classes.up}` :
                icons.iconChevron
            }
          />
        </a>
        {
          (this.state.showCallingOptions) ?
            <CallingOptions
              hideHeader
              data={this.props.data}
              galaxy={this.props.galaxy}
            /> :
            null
        }
      </div>
    );
  }
}

HasRTC.propTypes = {
  extension: PT.string.isRequired,
  data: PT.object.isRequired,
  show: PT.bool,
  galaxy: PT.bool,
};

HasRTC.defaultProps = {
  show: false,
  galaxy: false,
};

export default HasRTC;
