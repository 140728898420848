import React from 'react';
import { PropTypes as PT } from 'prop-types';
import Translate from 'components/Common/translate';
import macApp from 'images/ls-mac-app.svg';
import google from 'images/google-play.svg';
import windows from 'images/windows.svg';
import macStore from 'images/ls-mac-app-copy.svg';
import classes from 'styles/Downloads/tabletContent.module.scss';
import downloads from 'data/downloads.json';
import { browserUrls } from 'helpers/browser-utils';

const TabletContent = ({ isAndroid }) => (
  <div className={classes.container}>
    <div className={classes.header}>
      <Translate id="downloadsHeader" />
    </div>
    <div className={classes.subHeader}>
      <Translate id="downloadsSubHeader1" />
    </div>
    <div className={classes.contentFlexWrapper}>
      <div className={classes.subSection}>
        <div className={classes.subSectionHeader}>
          <Translate id="downloadsSubSectionHeader2" />
        </div>
        <div className={classes.subSectionContent}>
          <Translate id="downloadsSubSectionContent2" />
        </div>
        <div className={classes.buttonContainer}>
          {
            (isAndroid) ?
              <a href={downloads.androidDownloadURI} rel="noopener noreferrer" target="_blank">
                <img alt="" className={classes.google} src={google} />
              </a> :
              <a href={downloads.iOSDownloadURI} rel="noopener noreferrer" target="_blank">
                <img alt="" className={classes.macStore} src={macStore} />
              </a>
          }
        </div>
        <div className={classes.subSection}>
          <div className={classes.subSectionHeader}>
            <Translate id="downloadsSubSectionHeader1" />
          </div>
          <div className={classes.subSectionContent}>
            <Translate id="downloadsSubSectionContent1" />
          </div>
          <div className={classes.buttonContainer}>
            <a href={downloads.osxDownloadURI} rel="noopener noreferrer">
              <img alt="" className={classes.macApp} src={macApp} />
            </a>
            <a href={downloads.windowsDownloadURI} rel="noopener noreferrer">
              <img alt="" className={classes.windows} src={windows} />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div className={classes.header}>
      <Translate id="downloadsHeader2" />
    </div>
    <div className={classes.subHeader}>
      <Translate id="downloadsSubHeader2" />
    </div>
    <div className={classes.contentFlexWrapper}>
      <div className={classes.subSection}>
        <div className={classes.subSectionHeader}>
          <Translate id="downloadsSubSectionHeader3" />
        </div>
        <div className={classes.subSectionContent}>
          <Translate id="downloadsSubSectionContent3" />
        </div>
        <div className={classes.buttonContainer}>
          <a target="_blank" rel="noopener noreferrer" href={browserUrls.chrome}>
            <div className={`${classes.browserLogo} ${classes.chrome}`} />
          </a>
          <a target="_blank" rel="noopener noreferrer" href={browserUrls.edge}>
            <div className={`${classes.browserLogo} ${classes.edge}`} />
          </a>
        </div>
        <div className={classes.subSectionContent}>
          <Translate id="downloadsSubSectionContent4" />
        </div>
        <div className={classes.buttonContainer}>
          <a target="_blank" rel="noopener noreferrer" href={browserUrls.chrome}>
            <div className={`${classes.browserLogo} ${classes.chrome}`} />
          </a>
          <a target="_blank" rel="noopener noreferrer" href={browserUrls.edge}>
            <div className={`${classes.browserLogo} ${classes.edge}`} />
          </a>
        </div>
        <div className={classes.subSectionContent}>
          <Translate id="downloadsSubSectionContent4-1" />
        </div>
        <div className={classes.subSectionContent}>
          <Translate id="downloadsSubSectionContent4-1" />
        </div>
        <div className={classes.buttonContainer}>
          <div className={classes.logoSpacer}>
            <a target="_blank" rel="noopener noreferrer" href={browserUrls.safari}>
              <div className={`${classes.browserLogo} ${classes.safari}`} />
            </a>
            <a target="_blank" rel="noopener noreferrer" href={browserUrls.ie}>
              <div className={`${classes.browserLogo} ${classes.ie}`} />
            </a>
            <a target="_blank" rel="noopener noreferrer" href={browserUrls.firefox}>
              <div className={`${classes.browserLogo} ${classes.firefox}`} />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
);

TabletContent.propTypes = {
  isAndroid: PT.bool,
};

TabletContent.defaultProps = {
  isAndroid: false,
};

export default TabletContent;
