import React from 'react';
import { PropTypes as PT } from 'prop-types';
import { Redirect } from 'react-router';
import browserInfo from 'helpers/browser-utils';
import Header from 'components/Common/header';
import Footer from 'components/Common/footer';
import CallingOptions from 'components/CallMe/callingOptions';
import CallingOptionsMobile from 'components/CallMe/callingOptionsMobile';
import DesktopCallout from 'components/CallMe/desktopCallout';
import classes from 'styles/CallMe/baseLayout.module.scss';
import axios from 'axios';
import ReactSpinner from 'react-spinjs-fix';
import analytics from 'helpers/analytics';
import downloads from 'data/downloads.json';
import extend from 'extend';
import InternationalizationProvider from '../../providers/internationalization';

class OtherWaysLayout extends React.Component {
  static contextType = InternationalizationProvider;
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      loading: true,
      notFound: false,
    };
  }

  componentDidMount() {
    this.init();
    analytics.track('landed-OtherWays');
  }

  getOptionsComponent() {
    if (browserInfo.isMobile || browserInfo.isTablet) {
      return (
        <CallingOptionsMobile
          data={this.state.data}
          extension={this.props.match.params.ext}
          galaxy={this.state.data.extensionMetadata.galaxy}
        />
      );
    }
    return (
      <CallingOptions
        data={this.state.data}
        galaxy={this.state.data.extensionMetadata.galaxy}
      />
    );
  }

  init() {
    const self = this;
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/fetch/getAll/${this.props.match.params.ext}`
      )
      .then((response) => {
        self.setState({ loading: false });
        const data = extend(response.data, downloads, {
          userExtension: this.props.match.params.ext,
        });
        self.setState({ data });
      })
      .catch(() => {
        self.setState({ loading: false, notFound: true });
      });
  }

  render() {
    if (this.state.loading) {
      const spinConf = {
        length: 20,
        width: 10,
        radius: 22,
        position: 'absolute',
      };
      return <ReactSpinner config={spinConf} />;
    }

    if (
      this.state.notFound ||
      (!this.state.loading &&
        this.state.data &&
        !this.state.data.extensionMetadata)
    ) {
      return <Redirect to="/notFound" />;
    }

    if (
      !this.state.notFound &&
      !this.state.loading &&
      this.state.data &&
      this.state.data.extensionMetadata
    ) {
      return (
        <div className={classes.container}>
          <Header />
          <div className={classes.content} id="mainContent">
            <div className={classes.callMeContent}>
              {!browserInfo.isTablet && !browserInfo.isMobile && (
                <DesktopCallout extension={this.props.match.params.ext} />
              )}
              {this.getOptionsComponent()}
              <Footer />
            </div>
          </div>
        </div>
      );
    }
    return <div />;
  }
}

OtherWaysLayout.propTypes = {
  match: PT.object.isRequired,
};

OtherWaysLayout.contextTypes = {
  messages: PT.object,
};

export default OtherWaysLayout;
